import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  dissoc,
  isNil,
  pathOr,
  pick,
  propOr,
  values as ramdaValues
} from 'ramda'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import calendarIcon from 'assets/images/calendar.svg'
import AbsenceFormSelect from 'components/AbsenceFormSelect'
import { ABSENCE_TYPES } from 'utils/absences'
import Collapse from '@material-ui/core/Collapse'
import { Button, Input } from 'de-suite-me-ui'
import Checkbox from 'components/atoms/Checkbox'
import {
  addAbsenceRoutine,
  updateAbsenceRoutine
} from 'features/calendar/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate, formatPayloadDate } from 'utils/date'
import { selectIsAddingAbsence } from 'ducks/loaderSelector'
import { selectIsAdmin } from 'features/auth/ducks/selectors'
import { selectHolidays } from 'features/calendar/ducks/selectors'
import { MobileContent, mobileCss, WebContent } from 'components/RWD'

const emptyValues = {
  dateFrom: moment(),
  dateTo: moment(),
  isHalfDay: false,
  comment: '',
  type: 'placeholder',
  status: 'accepted'
}

const ModalContent = ({ type, onClose, profileView, data }) => {
  const { user, absence, date, viewType, calendarDate } = data
  const [values, setValues] = useState(emptyValues)
  const [isOtherAbsenceWithin, setIsOtherAbsenceWithin] = useState(false)
  const [moreExpanded, setMoreExpanded] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsAddingAbsence)
  const hasChildcareDays = propOr(0, 'annualChildcareDays', user) > 0
  const isAdmin = useSelector(selectIsAdmin)
  const holidays = useSelector(selectHolidays)
  const holidaysDates = holidays.map(holiday => formatDate(holiday.date))

  useEffect(() => {
    if (type === 'add' && date) {
      handleValueChange('dateFrom', moment(date))
      handleValueChange('dateTo', moment(date))
    }
  }, [])

  useEffect(() => {
    absence &&
      setValues({
        ...pick(['isHalfDay', 'type'], absence),
        dateFrom: moment(absence.dateFrom),
        dateTo: moment(absence.dateTo),
        comment: isNil(absence.comment) ? '' : absence.comment
      })
  }, [absence])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = (name, value) => {
    value && handleValueChange('dateTo', values.dateFrom)
    handleValueChange(name, value)
  }

  const onDateChange = dates => {
    switch (focusedInput) {
      case 'startDate':
        handleValueChange('dateFrom', dates.startDate)
        handleValueChange(
          'dateTo',
          values.isHalfDay ? dates.startDate : dates.endDate
        )
        break
      case 'endDate':
        handleValueChange(
          'dateFrom',
          values.isHalfDay ? dates.endDate : dates.startDate
        )
        handleValueChange('dateTo', dates.endDate)
        break
      default:
        return null
    }
  }

  const onFocusChange = inputType => {
    !isNil(focusedInput) && values.isHalfDay
      ? setFocusedInput(null)
      : setFocusedInput(inputType)
  }

  const handleMoreOptionOpen = () => setMoreExpanded(true)
  const handleMoreOptionClose = () => setMoreExpanded(false)

  const userName = propOr('', 'firstName', user)
  const daysLeft = propOr(0, 'availableHolidayDays', user)

  useEffect(() => {
    const isOtherAbsence = propOr([], 'absences', user).some(oldAbsence => {
      if (absence) {
        return (
          oldAbsence.id !== absence.id &&
          moment(oldAbsence.dateFrom) >= values.dateFrom &&
          moment(oldAbsence.dateTo) <= values.dateTo
        )
      } else {
        return (
          moment(oldAbsence.dateFrom) >= values.dateFrom &&
          moment(oldAbsence.dateTo) <= values.dateTo
        )
      }
    })
    setIsOtherAbsenceWithin(isOtherAbsence)
  }, [values])

  const handleSubmit = () => {
    switch (type) {
      case 'add':
        return dispatch(
          addAbsenceRoutine({
            ...values,
            employeeId: user.id,
            dateFrom: formatPayloadDate(values.dateFrom),
            dateTo: formatPayloadDate(values.dateTo),
            viewType,
            callback: () => {
              setValues(emptyValues)
              onClose()
            },
            clickedDate: date,
            calendarDate
          })
        )
      case 'edit':
        return dispatch(
          updateAbsenceRoutine({
            ...values,
            id: absence.id,
            employeeId: user.id,
            dateFrom: formatPayloadDate(values.dateFrom),
            dateTo: formatPayloadDate(values.dateTo),
            callback: onClose,
            viewType,
            clickedDate: date,
            calendarDate
          })
        )
      default:
        return console.error('type is undefined')
    }
  }

  const isButtonDisabled = useMemo(() => {
    return (
      ramdaValues(values).some(val => isNil(val)) ||
      values.type === 'placeholder' ||
      isLoading
    )
  }, [values])

  const screenWidth = pathOr(0, ['screen', 'width'], window)

  return (
    <>
      <ModalTitle>
        <UserName>{userName},&nbsp;</UserName>
        <DaysLeftMessage>
          <WebContent>
            You have <DaysLeftAmount>{daysLeft}</DaysLeftAmount> holiday days
            until the end of the year️
          </WebContent>
          <MobileContent>
            You have <DaysLeftAmount>{daysLeft}</DaysLeftAmount> holiday days
            left️
          </MobileContent>
        </DaysLeftMessage>
      </ModalTitle>
      <AbsenceForm>
        <img src={calendarIcon} alt='calendar-icon' />
        <DateRangePicker
          appendToBody={profileView}
          small
          noBorder
          readOnly
          startDate={values.dateFrom}
          orientation={screenWidth < 768 ? 'vertical' : 'horizontal'}
          withFullScreenPortal={screenWidth < 768}
          startDateId={Math.random() * 99999}
          endDate={values.dateTo}
          endDateId={Math.random() * 99999}
          onDatesChange={onDateChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          isDayBlocked={day => [6, 7].includes(day.isoWeekday())}
          minimumNights={0}
          minDate={isAdmin ? moment().subtract(1, 'year') : moment()}
          displayFormat='DD.MM.YYYY'
          hideKeyboardShortcutsPanel
          isOutsideRange={day => {
            return (
              propOr([], 'absences', user).some(
                oldAbsence =>
                  (absence
                    ? oldAbsence.id !== absence.id &&
                      day.isBetween(
                        oldAbsence.dateFrom,
                        oldAbsence.dateTo,
                        'day',
                        '[]'
                      )
                    : day.isBetween(
                        oldAbsence.dateFrom,
                        oldAbsence.dateTo,
                        'day',
                        '[]'
                      )) || holidaysDates.includes(formatDate(day))
              ) && ![6, 7].includes(day.isoWeekday())
            )
          }}
        />
        <FormDivider>/</FormDivider>
        <AbsenceFormSelect
          value={values.type}
          name='type'
          onChange={handleValueChange}
          options={
            hasChildcareDays
              ? ramdaValues(ABSENCE_TYPES)
              : ramdaValues(dissoc('childcare', ABSENCE_TYPES))
          }
        />
      </AbsenceForm>
      <MoreOptionsButton
        onClick={moreExpanded ? handleMoreOptionClose : handleMoreOptionOpen}
      >
        {moreExpanded ? 'Hide options' : 'More options'}
      </MoreOptionsButton>
      <Collapse in={moreExpanded}>
        <CheckboxWrapper>
          <Checkbox
            value={values.isHalfDay}
            name='isHalfDay'
            label='Is a half day absence'
            onChange={handleCheckboxChange}
          />
        </CheckboxWrapper>
        <Input
          name='comment'
          value={values.comment}
          onChange={e => handleValueChange('comment', e.target.value)}
          label='Comment'
        />
      </Collapse>
      <ButtonWrapper>
        <Button
          onClick={handleSubmit}
          color='secondary'
          disabled={isButtonDisabled || isOtherAbsenceWithin}
        >
          {isOtherAbsenceWithin
            ? 'You cannot overwrite existing absence'
            : type === 'add'
            ? 'Add Absence'
            : 'Save Absence'}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default ModalContent

const ModalTitle = styled.div`
  display: flex;

  & > div {
    display: inline-block;
  }
`

const UserName = styled.span`
  font-size: 19px;

  ${mobileCss(`
    display: none;
  `)}
`

const DaysLeftMessage = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.gray};
  padding-left: 5px;

  ${mobileCss(`
    margin-bottom: 5px;
  `)}
`

const DaysLeftAmount = styled.span`
  font-size: 18px;
  padding: 0 3px;
  color: ${({ theme }) => theme.palette.primary.main};
`

const AbsenceForm = styled.div`
  width: 100%;
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.palette.common.whisper};
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;

  img {
    width: 30px;
    margin: 0 10px 2px 0;

    ${mobileCss(`
      margin-bottom: 10px;
    `)}
  }

  ${mobileCss(`
    flex-direction: column;
  `)}
`

const FormDivider = styled.div`
  color: ${({ theme }) => theme.palette.common.dustyGray};

  ${mobileCss(`
    display: none;
  `)}
`

const MoreOptionsButton = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  transition: all 0.3s;
  margin: 7px 0;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const CheckboxWrapper = styled.div`
  margin: 10px 0;
`
