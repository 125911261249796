import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import {
  ChevronLeft,
  ChevronRight
} from 'features/userProfile/components/UserProfileAbsences'
import { isNotNilOrEmpty } from 'helpers/ramda'
import {
  selectCalendarData,
  selectHolidays
} from 'features/calendar/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, selectIsAdmin } from 'features/auth/ducks/selectors'
import { implementScrollableContainers } from 'utils/calendar'
import { formatDate } from 'utils/date'
import { pathOr, propOr } from 'ramda'
import { ABSENCE_TYPES } from 'utils/absences'
import { fetchCalendarDataRoutine } from 'features/calendar/ducks/actions'
import ovalShape from 'assets/oval.svg'
import { redirect } from 'utils/router'
import storageService from 'services/StorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import Tooltip from '@material-ui/core/Tooltip'
import CalendarPaper from 'features/calendar/components/CalendarPaper'
import AddAbsenceModal from 'features/calendar/components/AddAbsenceModal'
import UpdateAbsenceModal from 'features/calendar/components/UpdateAbsenceModal'
import { getMonthsInRange } from 'utils/calendar'
import { isEqual, sortBy, xorWith } from 'lodash'
import { fetchAllHolidaysRoutine } from 'features/calendar/ducks/actions'
import { Button } from 'de-suite-me-ui'

const weekDays = ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']

const emptyAbsenceModalValues = {
  date: '',
  user: {},
  openAdd: false,
  openEdit: false,
  absence: {}
}

const sortBySelectedUsers = (selectedUsers, currentUser) => (a, b) => {
  console.log('what', a.firstName, b.firstName
  )

  if (selectedUsers.includes(b.id)) {
    return 1
  }
}

const Calendar = () => {
    const [selectedUsers, setSelectedUsers] = useState(JSON.parse(localStorage.getItem('selectedUsers')) || [])
  const [date, setDate] = useState({
    year: moment().year(),
    month: Number(moment().format('MM'))
  })
  const currentUser = useSelector(getCurrentUser)
  const calendarUsers = useSelector(selectCalendarData(currentUser.id))
  const holidays = useSelector(selectHolidays)
  const holidaysDates = holidays.map(holiday => formatDate(holiday.date))
  const isAdmin = useSelector(selectIsAdmin)
  const dispatch = useDispatch()
  const [absenceModalValues, setAbsenceModalValues] = useState({})

  useEffect(() => { // @sieradz - fetch days off for the displayed year on the calendar
    if (date.year !== moment().year()) {    
      dispatch(fetchAllHolidaysRoutine(date.year))
    } else {
      dispatch(fetchAllHolidaysRoutine(moment().year()))
    }
  }, [date.year])


  const addOrRemoveUser = (el) => {
    setSelectedUsers(users => {

      const _newUsers = xorWith(users, [el], isEqual);

      // fall-through local storage
      localStorage.setItem('selectedUsers', JSON.stringify(_newUsers))

      return xorWith(users, [el], isEqual)
    })
  }

  const deselectAllUsers = () => {
    localStorage.setItem('selectedUsers', JSON.stringify([]))
    setSelectedUsers([])
  }

  const handleAbsenceModalOpen =
    ({ user, date, openAdd, openEdit }) =>
    () => {
      const absence =
        propOr([], 'absences', user).find(absence => {
          return date.isBetween(absence.dateFrom, absence.dateTo, 'day', '[]')
        }) ?? null

      setAbsenceModalValues({
        user,
        date,
        openAdd,
        openEdit,
        absence
      })
    }

  const handleAbsenceModalClose = () => {
    setAbsenceModalValues(emptyAbsenceModalValues)
    // dispatch(fetchCalendarDataRoutine(date))
  }

  const generateMonthlyReport = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const token = storageService
      .get(LOCAL_STORAGE_KEYS.token)
      .replace('Bearer ', '')
    window.open(
      apiUrl +
        `/absence/report/${date.month}/${date.year}?token=${token}&type=download`
    )
  }

  const calendarData = useMemo(() => {
    if (isNotNilOrEmpty(calendarUsers) && isNotNilOrEmpty(currentUser)) {
      const index = calendarUsers.map(user => user.id).indexOf(currentUser.id)
      calendarUsers.unshift(calendarUsers.splice(index, 1)[0])
    }
    return calendarUsers
  }, [calendarUsers, currentUser])

  useEffect(() => {
    implementScrollableContainers()
  }, [])

  useEffect(() => {
    dispatch(fetchCalendarDataRoutine(date))
  }, [date])

  const daysInMonth = useMemo(() => {
    return Array.from(
      {
        length: moment(`${date.year}-${date.month}`, 'YYYY-MM').daysInMonth()
      },
      (x, i) => {
        const day = moment(`${date.year}-${date.month}`)
          .startOf('month')
          .add(i, 'days')

        const d = new Date(`${date.month}/${i + 1}/${date.year}`)

        return {
          dayName: weekDays[d.getDay()],
          month: d.getMonth() + 1,
          dayNumber: i + 1,
          isHolidayOrWeekend:
            [0, 6].includes(d.getDay()) ||
            holidaysDates.includes(
              formatDate(`${date.month}/${i + 1}/${date.year}`)
            ),
          fullDate: day
        }
      }
    )
  }, [date])

  const handleDataChange = direction => () => {
    switch (direction) {
      case 'prev':
        if (date.month === 1) {
          setDate({ year: Number(date.year - 1), month: 12 })
        } else {
          setDate({ ...date, month: Number(date.month) - 1 })
        }
        break
      case 'next':
        if (date.month === 12) {
          setDate({ year: Number(date.year) + 1, month: 1 })
        } else {
          setDate({ ...date, month: Number(date.month) + 1 })
        }
        break
      default:
        break
    }
  }

  const getUserAbsences = userId => {
    const user = calendarUsers.find(user => user.id === userId)

    if (user.absences) {
      const filteredAbsences = user.absences.filter(absence => {
        const months = getMonthsInRange(absence.dateFrom, absence.dateTo)
        return months.some(
          item => item.month === date.month && item.year === date.year
        )
      })
      return filteredAbsences.sort((a, b) =>
        a.dateFrom.localeCompare(b.dateFrom)
      )
    } else {
      return []
    }
  }

  const sortedData = sortBy(calendarData, el => {
    return el.id !== currentUser.id && !selectedUsers.includes(el.id)
  })

  return (
    <div>
      <CalendarPaper>
        <TableWrapper>
          <TableHeaderWrapper>
            <LeftColumn>
              <MonthSelectionWrapper>
                <ChevronLeft onClick={handleDataChange('prev')} />
                {/* moment iterates months from 0; get month as moment object, subtract one month and then format it */}
                {moment().month(date.month).subtract(1, 'month').format('MMMM')}{' '}
                {date.year}
                <ChevronRight onClick={handleDataChange('next')} />
              </MonthSelectionWrapper>
              {isAdmin && (
                <MonthlyReport onClick={generateMonthlyReport}>
                  monthly report
                </MonthlyReport>
              )}
            </LeftColumn>
            <RightColumn className='container'>
              <DaysWrapper length={daysInMonth.length}>
                {isNotNilOrEmpty(daysInMonth) &&
                  daysInMonth.map(day => {
                    return (
                      <DayHeaderItem
                        key={day.dayNumber}
                        active={
                          day.dayNumber === Number(new Date().getDate()) &&
                          day.month === new Date().getMonth() + 1
                        }
                        isWeekend={day.isHolidayOrWeekend}
                      >
                        <div>{day.dayName}</div>
                        <div>{day.dayNumber}</div>
                      </DayHeaderItem>
                    )
                  })}
              </DaysWrapper>
            </RightColumn>
          </TableHeaderWrapper>

          {selectedUsers.length > 0 ? <Flex>
            <LeftColumn><div style={{ marginTop: 12 }}><Button type={'button'} onClick={deselectAllUsers}>DeSelect all</Button></div></LeftColumn>
          </Flex>: null}

          <CalendarContent>
            <LeftColumn>
              {calendarData &&
                sortedData.map((user, index) => {
                  return (
                    <CalendarRow key={`${user.id}-name`} first={index === 0}>
                      {currentUser.id !== user.id ? <div style={{ marginRight: 8 }}>
                        <input checked={selectedUsers.includes(user.id)} type={'checkbox'} onChange={() => addOrRemoveUser(user.id)} />
                      </div> : null}
                      <UserName
                        first={index === 0}
                        onClick={() => redirect(`/employee/${user.id}`)}
                      >
                        <img src={user.avatar} alt='user-avatar' />
                        {user.fullName}
                      </UserName>
                    </CalendarRow>
                  )
                })}
            </LeftColumn>
            <RightColumn className='container'>
              {calendarData &&
                sortedData.map((user, index) => {
                  const userAbsences = getUserAbsences(user.id)
                  const absenceDays = userAbsences.map(absence => {
                    let currentDate = moment(absence.dateFrom).clone()
                    const days = []
                    while (currentDate.isSameOrBefore(absence.dateTo)) {
                      /* check weekends and holidays */
                      if (
                        ![6, 7].includes(currentDate.isoWeekday()) &&
                        !holidaysDates.includes(formatDate(currentDate))
                      ) {
                        days.push({
                          day: Number(currentDate.format('D')),
                          month: Number(currentDate.format('MM')),
                          type: absence.type,
                          comment: absence.comment,
                          isHalfDay: absence.isHalfDay
                        })
                      }
                      currentDate = moment(currentDate).add(1, 'day')
                    }
                    return days
                  })

                  return (
                    <CalendarRow key={user.id} first={index === 0}>
                      <DaysWrapper length={daysInMonth.length}>
                        {daysInMonth &&
                          daysInMonth.map((el, index) => {
                            const findAbsence = absenceDays.find(day =>
                              day.some(
                                day =>
                                  day.day === index + 1 &&
                                  day.month === date.month
                              )
                            )

                            const isDisabled =
                              [6, 7].includes(el.fullDate.isoWeekday()) ||
                              holidaysDates.includes(formatDate(el.fullDate))

                            const canAddAbsence =
                              (!isDisabled &&
                                user.id === currentUser.id &&
                                moment(el.fullDate).isSameOrAfter(
                                  moment(),
                                  'day'
                                )) ||
                              isAdmin

                            const isUpdate = isNotNilOrEmpty(findAbsence)

                            return (
                              <DotWrapper
                                withPointer={canAddAbsence}
                                onClick={
                                  canAddAbsence
                                    ? handleAbsenceModalOpen({
                                        user,
                                        date: el.fullDate,
                                        openAdd: !isUpdate,
                                        openEdit: isUpdate
                                      })
                                    : () => {}
                                }
                                key={`dot-${index}-${Math.random() * 999}`}
                              >
                                {isNotNilOrEmpty(findAbsence) && !isDisabled ? (
                                  isNotNilOrEmpty(findAbsence[0].comment) ? (
                                    <Tooltip title={findAbsence[0].comment}>
                                      <DotImageWrapper
                                        isHalfDay={
                                          findAbsence.find(
                                            abs => abs.day === el.dayNumber
                                          ).isHalfDay
                                        }
                                      >
                                        <img
                                          src={pathOr(
                                            '',
                                            [findAbsence[0].type, 'icon'],
                                            ABSENCE_TYPES
                                          )}
                                          alt='absence-icon'
                                        />
                                      </DotImageWrapper>
                                    </Tooltip>
                                  ) : (
                                    <DotImageWrapper
                                      isHalfDay={
                                        findAbsence.find(
                                          abs => abs.day === el.dayNumber
                                        ).isHalfDay
                                      }
                                    >
                                      <img
                                        src={pathOr(
                                          '',
                                          [findAbsence[0].type, 'icon'],
                                          ABSENCE_TYPES
                                        )}
                                        alt='absence-icon'
                                      />
                                    </DotImageWrapper>
                                  )
                                ) : (
                                  <Dot className='dot' disabled={isDisabled} />
                                )}
                              </DotWrapper>
                            )
                          })}
                      </DaysWrapper>
                    </CalendarRow>
                  )
                })}
            </RightColumn>
          </CalendarContent>
        </TableWrapper>
      </CalendarPaper>
      <AddAbsenceModal
        open={absenceModalValues.openAdd}
        user={absenceModalValues.user}
        date={absenceModalValues.date}
        handleModalClose={handleAbsenceModalClose}
        calendarDate={date}
        profileView
      />
      <UpdateAbsenceModal
        open={absenceModalValues.openEdit}
        user={absenceModalValues.user}
        absence={absenceModalValues.absence}
        date={absenceModalValues.date}
        onModalClose={handleAbsenceModalClose}
        viewType='calendar'
        calendarDate={date}
        profileView
      />
    </div>
  )
}

export default Calendar


const Flex = styled.div({
  display: 'flex'
})

const TableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  position: sticky;
  top: -40px;
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 3;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.dustyGray};
`

const CalendarContent = styled.div`
  display: flex;
  padding: 10px 0;
  position: relative;
  min-height: 100px;

  &::before {
    content: '';
    height: 1px;
    background-color: ${({ theme }) => theme.palette.primary.light};
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
  }
`

const MonthSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

const TableWrapper = styled.div`
  width: 100%;
`

const LeftColumn = styled.div`
  box-sizing: border-box;
  width: 230px;
  padding: 0 20px;
`

const RightColumn = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, 30px);
  grid-gap: 5px;
`

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;

  img {
    width: 20px;
  }

  ${({ withPointer }) =>
    withPointer &&
    css`
      cursor: pointer;
      .dot {
        &:hover {
          background-color: #f2f2f2;
        }
      }
    `}
`

const Dot = styled.div`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 50%;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.palette.common.catskillWhite};
    `}
`

const DayHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30px;

  ${({ active }) =>
    active &&
    css`
      & > div:last-of-type {
        background-color: ${({ theme }) => theme.palette.secondary.main};
        color: ${({ theme }) => theme.palette.common.white};
        border-radius: 50%;
        padding: 0 5px;
      }
    `}

  ${({ isWeekend }) =>
    isWeekend &&
    css`
      color: ${({ theme }) => theme.palette.primary.main};
    `}
`

const CalendarRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;

  ${({ first }) =>
    first &&
    css`
      color: ${({ theme }) => theme.palette.secondary.main};
      font-weight: bold;
      margin-bottom: 10px;
    `}
`

const UserName = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: auto;
  height: 40px;
  padding-left: 5px;
  cursor: pointer;
  transition: all 0.3s;

  img {
    width: 30px;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    z-index: 1;
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  &::before {
    content: '';
    position: absolute;
    width: 34px;
    left: 3px;
    height: 34px;
    background: transparent url(${ovalShape}) no-repeat center/cover;
  }

  ${({ first }) =>
    first &&
    css`
      color: ${({ theme }) => theme.palette.secondary.main};
      font-weight: bold;
    `}
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const MonthlyReport = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.light};
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const DotImageWrapper = styled.div`
  position: relative;

  ${({ isHalfDay }) =>
    isHalfDay &&
    css`
      &::before {
        content: '';
        box-sizing: border-box;
        width: 9px;
        height: 22px;
        position: absolute;
        z-index: 1;
        right: 0;
        background-color: ${({ theme }) => theme.palette.common.white};
      }
    `}
`
